import styled from 'styled-components';

export const KnowledgesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px 2.5%;
  grid-gap: 15px 2.5%;

  @media (min-width: 599px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (min-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;
